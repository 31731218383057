import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import CallApi from "../components/CallApi";
import Personnummer from "personnummer";

const handleSwishPayment = async (
  customerNumber,
  mobilnummer,
  personnummer,
  sum,
  customerId,
) => {
  const body = {
    payerPaymentReference: customerNumber.toString(),
    payeeAlias: mobilnummer,
    payeeSSN: personnummer,
    amount: sum.toString(),
    kundId: customerId,
  };

  return await CallApi("POST", "/payout", body);
};

const formatPhoneNumber = (number) => {
  // Remove all non-digit characters
  let cleaned = number.replace(/\D/g, "");
  // Remove leading zero if present
  if (cleaned.startsWith("0")) {
    cleaned = cleaned.substring(1);
  }
  // Add '46' prefix
  return `46${cleaned}`;
};

const SwishPaymentDialog = ({
  isOpen,
  handleClose,
  header,
  abort,
  handleSubmit,
  customerNumber,
  customerId,
  sum,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, seterror] = useState("");
  const [phoneValidation, setPhoneValidation] = useState({
    isValid: false,
    message: "",
  });
  const [personnummerValidation, setPersonnummerValidation] = useState({
    isValid: false,
    message: "",
  });

  const mounted = useRef(true);
  const phoneTimeoutRef = useRef(null);
  const personnummerTimeoutRef = useRef(null);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      if (phoneTimeoutRef.current) {
        clearTimeout(phoneTimeoutRef.current);
      }
      if (personnummerTimeoutRef.current) {
        clearTimeout(personnummerTimeoutRef.current);
      }
    };
  }, []);

  const validatePhoneNumber = async (number) => {
    if (!number) {
      mounted.current && setPhoneValidation({ isValid: false, message: "" });
      return;
    }

    try {
      const response = await CallApi("GET", `/validate/${number}`);

      if (!mounted.current) return;

      if (!response || response.status !== 200) {
        setPhoneValidation({
          isValid: false,
          message: "Kunde inte validera telefonnumret",
        });
        return;
      }

      const data = response.data;
      if (data.valid && data.type === "mobile") {
        const formattedNumber = formatPhoneNumber(data.number);
        const mobilnummerRegex = /^46[1-9]{2}[^0]{1}[0-9]*$/;

        if (mobilnummerRegex.test(formattedNumber)) {
          setPhoneValidation({
            isValid: true,
            message: "Giltigt mobilnummer",
            formattedNumber: formattedNumber,
          });
        } else {
          setPhoneValidation({
            isValid: false,
            message: "Numret måste vara ett giltigt mobilnummer",
          });
        }
      } else {
        setPhoneValidation({
          isValid: false,
          message: "Ogiltigt mobilnummer",
        });
      }
    } catch (error) {
      mounted.current &&
        setPhoneValidation({
          isValid: false,
          message: "Kunde inte validera telefonnumret",
        });
    }
  };

  const handlePhoneChange = (e) => {
    const number = e.target.value.replace(/[+-]/g, "");

    if (phoneTimeoutRef.current) {
      clearTimeout(phoneTimeoutRef.current);
    }

    phoneTimeoutRef.current = setTimeout(() => {
      validatePhoneNumber(number);
    }, 500);
  };

  const validatePersonnummer = (number) => {
    if (!mounted.current) return;
    const cleaned = number.replace(/-/g, "");
    const isValid = Personnummer.valid(cleaned);

    if (isValid) {
      const parsed = Personnummer.parse(cleaned);
      const formattedNumber = `${parsed._fullYear}${parsed._month}${parsed._day}${parsed._num}${parsed._check}`;

      setPersonnummerValidation({
        isValid,
        message: "Giltigt personnummer",
        cleanedNumber: formattedNumber,
      });
    } else {
      setPersonnummerValidation({
        isValid: false,
        message: "Ogiltigt personnummer",
        cleanedNumber: cleaned,
      });
    }
  };

  const handlePersonnummerChange = (e) => {
    const number = e.target.value;

    if (personnummerTimeoutRef.current) {
      clearTimeout(personnummerTimeoutRef.current);
    }

    personnummerTimeoutRef.current = setTimeout(() => {
      validatePersonnummer(number);
    }, 500);
  };

  const handleOk = async () => {
    if (!personnummerValidation.isValid) {
      seterror("Personnumret ser inte ut att vara korrekt.");
      return;
    }

    if (!phoneValidation.isValid) {
      seterror("Mobilnumret ser inte ut att vara korrekt.");
      return;
    }
    console.log("Personnummer: ", personnummerValidation.cleanedNumber);
    console.log("Mobilnummer: ", phoneValidation.formattedNumber);
    setIsSubmitting(true);

    var response = await handleSwishPayment(
      customerNumber,
      phoneValidation.formattedNumber,
      personnummerValidation.cleanedNumber,
      Math.round(sum),
      customerId,
    );

    if (response.status === 201) {
      await handleSubmit(phoneValidation.formattedNumber);
    } else {
      seterror(
        "Något gick fel. Kontrollera att mobilnummer och personnummer är i korrekt format. Fungerar det ändå inte så kontakta Footly.",
      );
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img src="Swish.png" alt="Swish logo" className="swish-logo" />{" "}
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <p>
                Ange ditt personnummer och det mobilnumret du vill ha Swish
                till.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="Personnummer">
                <Form.Label>Personnummer: </Form.Label>
                <Form.Control
                  type="text"
                  name="Personnummer"
                  placeholder="Personnummer"
                  onChange={handlePersonnummerChange}
                  isValid={personnummerValidation.isValid}
                  isInvalid={
                    personnummerValidation.message &&
                    !personnummerValidation.isValid
                  }
                />
                <Form.Control.Feedback
                  type={personnummerValidation.isValid ? "valid" : "invalid"}
                >
                  {personnummerValidation.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="Mobilnummer">
                <Form.Label>Mobilnummer:</Form.Label>
                <Form.Control
                  type="text"
                  name="Mobilnummer"
                  placeholder="Mobilnummer"
                  onChange={handlePhoneChange}
                  isValid={phoneValidation.isValid}
                  isInvalid={
                    phoneValidation.message && !phoneValidation.isValid
                  }
                />
                <Form.Control.Feedback
                  type={phoneValidation.isValid ? "valid" : "invalid"}
                >
                  {phoneValidation.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <Form.Label>{error}</Form.Label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>
          Avbryt
        </Button>
        {!abort && (
          <Button
            variant="primary"
            type="submit"
            onClick={handleOk}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Vänta..." : "Ok"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SwishPaymentDialog;
